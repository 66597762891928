<template>
  <div class="container-fluid">
    <div class="px-4 pt-3 pb-5">
      <div class="bg p-3">
        <portal to="breadcrumb">
          <b-breadcrumb class="pl-5 pt-4 pb-0 ma-0" :items="breadcrumbs"></b-breadcrumb>
        </portal>
        <div class="d-flex justify-content-between mb-3">
          <h4 class="text-dark">Liste des utilisateurs</h4>

          <!-- <acte-edit ref="actEditor" @saved="updateOrCreateActe" :item="activeActe" title="Ajouter un acte"></acte-edit> -->
          <form class="">
            <div class="form-group form-inline">
              <user-edit ref="structEditor" :hide-action-button="true" @saved="updateOrCreateUser" :item="activeStructure" title="Ajouter une structure"></user-edit>
              <b-button class="mr-3" @click="$refs.structEditor.show()" variant="success">Créer un utilisateur</b-button>
              <input type="text" class="form-control mr-0" name="" placeholder="Rechercher">
            </div>
          </form>
        </div>
        
        <div class="card border-0 shadow-0">
          <div class="card-body py-1">
            <div class="table-responsive">
              <b-table :fields="tableFields" borderless :th-class="['border-bottom', 'border-top']" striped hover :items="bookedStamps">
              <!-- <hr/> -->
                <template #head()="data">
                  <span>{{ data.label }} </span>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(actions)="data">
                <div class="d-flex">
                  <a href="#" @click.prevent="editActe(data.item)" title="Modifier" class="text-secondary mr-2"><i class="fa fa-edit"></i></a>
                  <a href="#" title="Supprimer" class="text-danger mr-2"><i class="fa fa-trash-alt"></i></a>
                  <a href="#" title="Détail" class="text-info mr-2"><i class="fa fa-eye"></i></a>
                </div>
              </template>

              <template #cell(acte)="data">
                <span>{{ data.value.libelle }}</span>
              </template>

              <template #cell(used)="data">
                <b-badge :variant="data.value?'warning':'success'">{{ data.value? 'Consommé' : 'Non consommé' }}</b-badge>
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>

              </b-table>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-3">
          <p>{{ bookedStamps.length }} éléments sur {{ bookedStamps.length }}</p>
          <nav class=''>
            <ul class="pagination justify-content-end">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import ActeEdit from '@/components/acte/ActeEdit'
import UserEdit from '@/components/espace/admin/user/UserEdit'

export default {
  components: {
    UserEdit
  },
  data () {
    return {
      structure: null,
      breadcrumbs: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Utilisateurs',
          href: '#',
          active: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activeStructure: 'statistic/activeStructure',
      structures: 'structure/structures',
      bookedStamps: 'booking/bookedStamps',
    }),
    tableFields () {
      return [
        'index',
        { key: 'acte', label: 'Libellé' },
        // { key: 'cout', label: 'Libellé' },
        { key: 'cout', label: 'Coût' },
        { key: 'used', label: 'Libellé' },
        { key: 'used', label: 'Libellé' },
        { key: 'used', label: 'Libellé' },
        { key: 'dateCreation', label: 'Date' },
        'actions',
      ]
    },
    usableStamps () {
      return this.bookedStamps.filter(item => !item.used).slice(10)
    },
    usedStamps () {
      return this.bookedStamps.filter(item => item.used).slice(10)
    }
  },
  mounted () {
    this.fetchStructures().then(data => {
      console.log(data)
    })
    this.fetchStampsByStructure({
      structureId: 1
    }).then(d=>{
      console.log(d)
    })
    this.structure = this.activeStructure
  },
  methods: {
    ...mapActions({
      selectStructure: 'statistic/selectStructure',
      fetchStructures: 'structure/fetchStructures',
      fetchStampsByStructure: 'booking/fetchStampsByStructure',
    }),
    updateOrCreateUser(user) {
      console.log(user)
    },
    reloadStatistics (item) {
      let structureId = item != null? item.id : null
      this.selectStructure(item)
      this.fetchStampsByStructure({
        structureId
      })
    }
  }
}
</script>

<style>

</style>