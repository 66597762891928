<template>
  <div>
    <slot name="action"> <b-button v-if="!hideActionButton" variant="success" v-b-modal.modal-footer-sm>Créer</b-button> </slot>
    <b-modal id="modal-footer-sm" ref="modal" @ok="ok" @cancel="cancel" :title="title" modal-ok="Valider" modal-cancel="Annuler">
      <form class="">
        <div class="row">
          <div class="col-sm-12 form-group">
            <label for="libelle">Type de compte à créer</label>
            <b-form-select class="inline" placeholder="Sélectionner la structure" multiple v-model="editableItem.roles" :options="userTypeOptions"></b-form-select>
            <!-- <input type="text" v-model="editableItem.libelle" class="form-control" name="libelle" placeholder="Libellé de structure"> -->
          </div>
          <div class="col-sm-12 form-group">
            <label for="libelle">Nom de la structure</label>
            <b-form-select class="inline" v-if="edidtableItem.roles" placeholder="Sélectionner la structure" v-model="editableItem.structure" :options="structureOptions" @change="reloadStatistics"></b-form-select>
            <!-- <input type="text" v-model="editableItem.libelle" class="form-control" name="libelle" placeholder="Libellé de structure"> -->
          </div>
          <div class="col-sm-12 form-group">
            <label for="libelle">Email</label>
            <input type="text" v-model="editableItem.libelle" class="form-control" name="libelle" placeholder="Libellé de structure">
          </div>
          <div class="col-sm-12 form-group">
            <label for="libelle">Mot de passe</label>
            <input type="text" v-model="editableItem.libelle" class="form-control" name="libelle" placeholder="Libellé de structure">
          </div>
          <div class="col-sm-12 form-group">
            <label for="libelle">Confirmation mot de passe</label>
            <input type="text" v-model="editableItem.libelle" class="form-control" name="libelle" placeholder="Libellé de structure">
          </div>
        </div>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm"  class="style_btn btn rounded-0" @click="cancel()">
          Annuler
        </b-button>
        <b-button size="sm"  class="style_btn btn rounded-0 btn  btn-success" @click="ok()">
          Valider
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  // import Vue from 'vue'
  
export default {
  props: {
    title: {
      type:  String,
      required: true
    },
    hideActionButton: {
      type: Boolean,
      default: false
    },
    item: {
      type:  Object,
      default: () => ({ })
    }
  },
  data () {
    return {
      structure: null,
      editableItem: {}
    }
  },
  mounted () {
    if(this.structures.length === 0){
      this.fetchStructures()
    }
  },
  watch: {
    item () {
      this.editableItem = { ...this.item }
    }
  },
  computed: {
    ...mapGetters({
      structures: 'structure/structures'
    }),
    structureOptions () {
      let options = [
        { value: null, text: 'Toutes les structures' }
      ]
      this.structures.forEach(el=>{
        options.push({ value: el , text: el.libelle })
      })
      return options
    },
    userTypeOptions () {
      const options = [
        { value: null, text: 'Toutes les structures' },
        { value: 'ADMIN', text: 'Compte administrateur' },
        { value: 'STRUCTURE', text: 'Compte structure' },

      ]
      return options
    },
  },
  methods: {
    ...mapActions({
      fetchStructures: 'structure/fetchStructures',
    }),
    show () {
      this.$refs['modal'].show()
    },
    hide () {
      this.$refs['modal'].hide()
    },
    ok () {
      this.$emit('saved', this.editableItem)
      this.editableItem = {}
    },
    cancel () {
      this.$emit('canceled')
    }
  }
  
}
</script>